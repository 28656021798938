import { mapGetters } from "vuex"
export default {
  computed: {
    ...mapGetters({
        zoneDetails: 'getterZoneDetails',
        bookingDetails: "getterBookingDetails"
    }),
  },
  methods:{
    getAppID(){
      return this.zoneDetails?.MIDs_spherepay?.square_apgp?.APPID || this.bookingDetails?.booking?.MIDs_spherepay?.square_apgp?.APPID || ""
    },
    getLocationID(){
      return this.zoneDetails?.MIDs_spherepay?.square_apgp?.LOCATION_ID || this.bookingDetails?.booking?.MIDs_spherepay?.square_apgp?.LOCATION_ID || ""
    }
  }
};